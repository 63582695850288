import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-company-modal__body" }
const _hoisted_2 = { class: "add-company-modal__body__web-presence" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInformationGroup = _resolveComponent("AppInformationGroup")!
  const _component_CompanyInfoSectionItem = _resolveComponent("CompanyInfoSectionItem")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppModal = _resolveComponent("AppModal")!

  return (_openBlock(), _createBlock(_component_AppModal, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event)),
    size: "medium"
  }, {
    header: _withCtx(() => _cache[5] || (_cache[5] = [
      _createElementVNode("h3", { class: "modal-title" }, "Add organisation to the Net0 Platform", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_AppInformationGroup, {
          label: "COMPANY NAME",
          explanation: "Please specify the name of the organisations to be added",
          "title-font-weight": 400,
          isRequired: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              modelValue: _ctx.company.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company.name) = $event)),
              placeholder: "Type organisation name",
              margin: "6px 0 0"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_AppInformationGroup, {
          label: "COMPANY WEB PRESENCE",
          explanation: "Please add <b>at least one</b> of the following: website, LinkedIn page, or any relevant link that provides more information about the organisation.",
          "title-font-weight": 400,
          "allow-html": "",
          isRequired: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CompanyInfoSectionItem, {
                gap: "25px",
                "is-align-center": "",
                width: "188px"
              }, {
                title: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Organisation website ")
                ])),
                content: _withCtx(() => [
                  _createVNode(_component_AppInput, {
                    modelValue: _ctx.company.website,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.company.website) = $event)),
                    modelModifiers: { trim: true },
                    placeholder: "Type organisation website"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_CompanyInfoSectionItem, {
                gap: "25px",
                "is-align-center": "",
                width: "188px"
              }, {
                title: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Organisation LinkedIn URL ")
                ])),
                content: _withCtx(() => [
                  _createVNode(_component_AppInput, {
                    modelValue: _ctx.company.linkedInURL,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.company.linkedInURL) = $event)),
                    modelModifiers: { trim: true },
                    placeholder: "Type organisation LinkedIn URL"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_CompanyInfoSectionItem, {
                gap: "25px",
                information: "If the organistion lacks a website or LinkedIn page, please provide any link that offers additional information like Facebook, Twitter page, a news article, or a pitch deck.",
                "is-align-center": "",
                width: "188px"
              }, {
                title: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Organisation web presence ")
                ])),
                content: _withCtx(() => [
                  _createVNode(_component_AppInput, {
                    modelValue: _ctx.company.webPresence,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.company.webPresence) = $event)),
                    modelModifiers: { trim: true },
                    placeholder: "Type organisation web presence"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(({ hideModal }) => [
      _createVNode(_component_AppButton, {
        label: "Cancel",
        type: "empty",
        "data-testid": "cancel-button",
        onClick: hideModal,
        "is-disabled": _ctx.isLoading
      }, null, 8, ["onClick", "is-disabled"]),
      _createVNode(_component_AppButton, {
        label: "Add organisation",
        "data-testid": "add-button",
        "is-loading": _ctx.isLoading,
        "is-disabled": _ctx.isAddButtonDisabled,
        onClick: _ctx.handleAddClick
      }, null, 8, ["is-loading", "is-disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}