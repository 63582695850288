import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, TransitionGroup as _TransitionGroup } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_UnauthorizedView = _resolveComponent("UnauthorizedView")!
  const _component_ClaimProfileLayout = _resolveComponent("ClaimProfileLayout")!
  const _component_AppFreeTrialModal = _resolveComponent("AppFreeTrialModal")!
  const _component_AppLayout = _resolveComponent("AppLayout")!
  const _component_AppToast = _resolveComponent("AppToast")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isFreeTrialLayout)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_ctx.isUnauthorizedLayout)
        ? (_openBlock(), _createBlock(_component_UnauthorizedView, { key: 1 }))
        : (_ctx.isProfileClaimLayout)
          ? (_openBlock(), _createBlock(_component_ClaimProfileLayout, { key: 2 }))
          : (_ctx.isRouteReady)
            ? (_openBlock(), _createBlock(_component_AppLayout, { key: 3 }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view),
                  _createVNode(_component_AppFreeTrialModal, {
                    modelValue: _ctx.freeTrialModel,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.freeTrialModel) = $event)),
                    title: _ctx.title,
                    message: _ctx.message
                  }, null, 8, ["modelValue", "title", "message"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
    _createVNode(_TransitionGroup, {
      tag: "div",
      name: "notifications",
      class: "app__notifications"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationsStore.notifications, (notification) => {
          return (_openBlock(), _createBlock(_component_AppToast, _mergeProps({
            key: notification.id,
            ref_for: true
          }, notification, {
            onRemove: ($event: any) => (_ctx.notificationsStore.removeNotification(notification.id))
          }), null, 16, ["onRemove"]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}