import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-modal__content__sidebar"
}
const _hoisted_2 = { class: "app-modal__content__container" }
const _hoisted_3 = {
  key: 1,
  class: "app-modal__content__body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.appModalClass),
    ref: "appModal",
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleAppModalClick && _ctx.handleAppModalClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.appModalContentClass),
      style: _normalizeStyle(_ctx.appModalContentStyle)
    }, [
      (_ctx.$slots.sidebar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "sidebar", { hideModal: _ctx.hideModal }, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.$slots.header)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.appModalContentHeaderClass)
            }, [
              _renderSlot(_ctx.$slots, "header", { hideModal: _ctx.hideModal }, undefined, true)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.$slots.body)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "body", { hideModal: _ctx.hideModal }, undefined, true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.footer)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass(_ctx.appModalContentFooterClass)
            }, [
              _renderSlot(_ctx.$slots, "footer", { hideModal: _ctx.hideModal }, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ])
    ], 6)
  ], 34))
}