import { Currency, UserTeam } from "@/shared/types/common";
import { FinancialSource, NewsLabel } from "@/shared/types/organization";

export enum FundingRoundStatus {
  COMPLETED = "COMPLETED",
  ONGOING = "ONGOING",
  UPCOMING = "UPCOMING",
}
export interface FundingRoundModel {
  clientId?: number;
  coFundingRoundID?: number;
  roundCurrency?: string;
  roundInvestorsIDs: number[];
  roundNews?: NewNews[];
  roundDate?: string;
  roundType: string;
  id?: number;
  status: FundingRoundStatus;
  valuationAmount?: number;
  valuationCurrency?: string;
  originalRoundAmount?: number;
  financingType?: string;
}

export interface NewNews {
  id?: number | string;
  title?: string;
  url: string;
  genAI?: boolean;
}

export interface AddInvestorModel {
  name: string;
  website: string;
  linkedInURL: string;
  pitchbookURL?: string;
  twitterURL?: string;
  primaryTypeID?: number;
  secondaryTypeIDs?: number[];
}

export interface EditableFundingRoundNews {
  fundingRoundId?: number;
  googleTitle?: string;
  id?: number | string;
  url: string;
}

export interface EditableExternalProvider {
  provider: string;
  link: string;
}

export interface ExternalProviders {
  cbInsights: string;
  crunchbase: string;
  dealroom: string;
  i3: string;
  linkedIn: string;
  owler: string;
  pitchbook: string;
}

export interface WebCustomerRequestFilter {
  id?: number;
  role?: string;
  name?: string;
  surname?: string;
  email?: string;
  website?: string;
  startDate?: Date;
  endDate?: Date;
  export?: boolean;
  limit?: number;
  offset?: number;
  phone?: string;
  freeTrial?: boolean;
  preview?: boolean;
  internal?: boolean;
  enabled?: boolean;
}

export interface WebCustomerTableUser {
  id: number;
  role: string;
  name: string;
  surname: string;
  password?: string;
  website: string;
  email: string;
  phone: string;
  team?: UserTeam;
  startDate: string;
  endDate: string;
  acquisitionDate: string;
  admin: boolean;
  freeTrial: boolean;
  preview: boolean;
  enabled: boolean;
  internal: boolean;
  export: boolean;
  trustedSource: boolean;
  regexpFilterEnabled: boolean;
  pageSize?: number;
  selectedCurrency?: Currency;
}

export interface WebCustomerStats {
  companiesViewed: number;
  earliestCompanyViewed: string;
  latestCompanyViewed: string;
  searchesDone: number;
  earliestSearch: string;
  latestSearch: string;
}

export enum EditOrganizationButtonName {
  NOT_STARTUP,
  INACTIVE,
  NOT_GREEN,
  DUPLICATED,
  CONFIRM_COMPANY_INFO,
  CONFIRM_ROUND_INFO,
}

export const ADMIN_PANEL_TABS = {
  STARTUP_SUBMISSION: "startups-submission",
  TAXONOMY_ITEMS: "taxonomy-items",
  TAXONOMY_TYPES: "taxonomy-tag-types",
  TAXONOMY_FAMILIES: "taxonomy-families",
  TAXONOMY_RELATIONS: "taxonomy-relations",
  TAXONOMY_TAGS: "taxonomy-tags",
  WEB_CUSTOMERS: "web-customers",
  MARKET_REPORT: "market-report",
  DASHBOARD_REPORT: "dashboard-report",
  USER_TEAMS: "user-teams",
};

export interface UserTeamRequest {
  name?: string;
  domain?: string;
  limit: number;
  offset: number;
}

export interface CompanyClient {
  clientID: number;
  name: string;
  website: string;
  description?: string;
  logoUrl?: string;
  id?: number;
}

export interface CompanyFinancial {
  id: number;
  clientID: number;
  year?: number;
  currency: string;
  revenues: number;
  revenuesSource?: FinancialSource;
  revenuesEstimate: boolean;
  ebitda?: number;
  ebitdaSource?: FinancialSource;
  ebitdaEstimate?: boolean;
  attachments: FinancialAttachment[];
  newRow?: boolean;
  edited?: boolean;
}

export interface CompanyFinancialBody {
  id?: number;
  clientID: number;
  year?: number;
  currency: string;
  revenues: number;
  revenuesSourceID: number;
  revenuesEstimate?: boolean;
  ebitda?: number;
  ebitdaSourceID?: number;
  ebitdaEstimate?: boolean;
}

export interface FinancialAttachment {
  name: string;
  url: string;
  financialID: number;
  labels: NewsLabel[];
  id: number;
}
